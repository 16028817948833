<template lang="pug">
div(
    class="step-2"
    v-if="!!order"
)
    //- name bar
    TopBar
        OrderName(
            :order="order"
        )

    //- contact
    ContactBoxes(
        :order="order"
    )

    //- tabs
    div
        Spacer(
            small
        )
        Container
            IonText(
                color="tertiary"
            )
                h3(class="with-margin") Rechtliche bestimmungen
            IonSegment(
                v-model="tab"
                :scrollable="true"
                :class="tabsLocked ? 'pointer-events-none' : ''"
            )
                IonSegmentButton(
                    :value="0"
                )
                    IonLabel(
                        color="white"
                    ) AGB
                IonSegmentButton(
                    :value="1"
                )
                    IonLabel(
                        color="white"
                    ) Wiederruf
                IonSegmentButton(
                    :value="2"
                )
                    IonLabel(
                        color="white"
                    ) Unterschrift

        SegmentCard(
            style="background-color: var(--ion-color-primary-shade)"
        )
            //- agb
            div(
                v-show="tab == 0"
            )
                div(
                    class="mb-2"
                    @click="agbModal = true"
                ) {{ maxChar(tempAgbText, 120) }}
                IonRow(class="receding")
                    IonCol(
                        size="6"
                        class="d-flex flex-column pb-0"
                    )
                        CheckableButton(
                            :checked="agbRadio == 'agb-accepted'"
                            @click="agbRadio = 'agb-accepted'; tab = 1"
                        ) AGB gelesen und akzeptiert
                    IonCol(
                        size="6"
                        class="d-flex flex-column pb-0"
                    )
                        CheckableButton(
                            :checked="agbRadio == 'not-necessary'"
                            @click="agbRadio = 'not-necessary'; tab = 1"
                        ) Eingabe nicht notwendig

            //- wiederruf
            div(
                v-show="tab == 1"
            )
                div(
                    class="mb-2"
                    @click="revocationModal = true"
                ) {{ maxChar(tempRevocationText, 120) }}
                IonRow(class="receding")
                    IonCol(
                        size="6"
                        class="d-flex flex-column pb-0"
                    )
                        CheckableButton(
                            :checked="revocationRadio == 'revocation-accepted'"
                            @click="revocationRadio = 'revocation-accepted'; tab = 2"
                        ) Wiederruf gelesen und akzeptiert
                    IonCol(
                        size="6"
                        class="d-flex flex-column pb-0"
                    )
                        CheckableButton(
                            :checked="revocationRadio == 'revocation-necessary'"
                            @click="revocationRadio = 'revocation-necessary'; tab = 2"
                        ) Eingabe nicht notwendig

            //- signature
            div(
                v-if="tab == 2 || signatureModuleInitialized"
                :style="[tab != 2 && signatureModuleInitialized ? { display: 'none' } : {}]"
            )
                SignatureModule(
                    @updateSignatureExists="setSignatureExists"
                    @moduleLoading="tabsLocked = true"
                    @moduleReady="tabsLocked = false"
                    v-if="$route.name == 'order-2'"
                )
                    //- @updateSignatureExists="(val) => { signatureExists = val; }"
                IonRow(class="receding")
                    IonCol(
                        size="12"
                        class="d-flex flex-column pb-0"
                    )
                        CheckableButton(
                            :checked="signatureRadio == 'not-necessary'"
                            @click="signatureRadio != 'not-necessary' ? (signatureRadio = 'not-necessary') : (signatureRadio = false)"
                        ) Eingabe nicht notwendig

    //- buttons
    div(class="buttons-height-2")
        //- div(class="page-buttons-wrapper")
        //-     Container
        //-         IonRow(class="receding btn-container")
        //-             IonCol(
        //-                 size="6"
        //-                 class="pb-0"
        //-             )
        //-                 IonButton(
        //-                     color="secondary"
        //-                     size="large"
        //-                     expand="block"
        //-                 )
        //-                     IonText(
        //-                         color="primary"
        //-                     )
        //-                         h3(class="text-center") Karte starten

        //-             IonCol(
        //-                 size="6"
        //-                 class="pb-0"
        //-             )
        //-                 IonButton(
        //-                     color="secondary"
        //-                     size="large"
        //-                     expand="block"
        //-                 )
        //-                     IonText(
        //-                         color="primary"
        //-                     )
        //-                         h3 Ablehnen

        //-             IonCol(
        //-                 size="6"
        //-             )
        //-                 IonButton(
        //-                     color="secondary"
        //-                     size="large"
        //-                     expand="block"
        //-                 )
        //-                     IonText(
        //-                         color="primary"
        //-                     )
        //-                         h3 Verspätet

        //-             IonCol(
        //-                 size="6"
        //-             )
        //-                 IonButton(
        //-                     :color="nextButtonEnabled ? 'secondary' : 'accent'"
        //-                     size="large"
        //-                     expand="block"
        //-                     :disabled="!nextButtonEnabled"
        //-                     @click="$router.push({ name: 'order-3', params: { id: order.id } })"
        //-                 )
        //-                     //- @click="allTermsModal = true"
        //-                     IonText(
        //-                         color="primary"
        //-                     )
        //-                         h3 Weiter
    //- CustomModal(
    //-     v-model="allTermsModal"
    //- )
    //-     ConfirmModalContentWithSlot(
    //-         confirmButtonOnly
    //-         @confirm="acceptTerms"
    //-         secondVariant
    //-     )
    //-         Spacer
    //-         IonText(
    //-             color="white"
    //-         )
    //-             h3 AGB
    //-             p {{ tempAgbText }}
    //-             h3(class="mt-6") Wiederruf
    //-             p {{ tempRevocationText }}

    //- agb modals
    CustomModal(
        v-model="agbModal"
    )
        ConfirmModalContentWithSlot(
            confirmButtonOnly
            @confirm="agbModal = false"
            @additionalButtonClick="sendAgbModal = true"
            secondVariant
            additionalButton
            additionalButtonText="AGB per e-mail senden"
        )
            Spacer
            IonText(
                color="white"
            )
                p {{ tempAgbText }}
    CustomModal(
        v-model="sendAgbModal"
    )
        EmailSendModalContent(
            :order="order"
            @sendEmail="sendAgb"
            text="AGB per e-mail senden an"
        )

    //- revocation modals
    CustomModal(
        v-model="revocationModal"
    )
        ConfirmModalContentWithSlot(
            confirmButtonOnly
            @confirm="revocationModal = false"
            @additionalButtonClick="sendRevocationModal = true"
            secondVariant
            additionalButton
            additionalButtonText="Wiederruf per e-mail senden"
        )
            Spacer
            IonText(
                color="white"
            )
                p {{ tempRevocationText }}

    CustomModal(
        v-model="sendRevocationModal"
    )
        EmailSendModalContent(
            :order="order"
            @sendEmail="sendRevocation"
            text="Wiederruf per e-mail senden an"
        )
</template>

<script>
import TopBar from "@/components/sections/TopBar"
import ContactBoxes from "@/components/sections/ContactBoxes"
import OrderName from "@/components/molecules/OrderName"
import CheckableButton from "@/components/molecules/CheckableButton"
import SignatureModule from "@/components/molecules/SignatureModule"
import SegmentCard from "@/components/molecules/SegmentCard"
import CustomModal from "@/components/molecules/modals/CustomModal"
import ConfirmModalContentWithSlot from "@/components/molecules/modals/ConfirmModalContentWithSlot"
import EmailSendModalContent from "@/components/molecules/modals/EmailSendModalContent"
import { ref, computed, watch } from "vue"
import { useRouter } from "vue-router"
import { useUtilities } from "@/composables/utilities"
import { useBtnController } from "@/composables/buttons-controller"

export default {
    components: {
        TopBar,
        OrderName,
        ContactBoxes,
        CheckableButton,
        SignatureModule,
        SegmentCard,
        CustomModal,
        ConfirmModalContentWithSlot,
        EmailSendModalContent
    },
    props: {
        order: {
            required: true
        }
    },

    setup(props) {
        const tab = ref(0)
        const agbRadio = ref(false)
        const revocationRadio = ref(false)
        const signatureRadio = ref(false)
        const allTermsModal = ref(false)
        const signatureExists = ref()

        const agbModal = ref(false)
        const revocationModal = ref(false)

        const sendAgbModal = ref(false)
        const sendRevocationModal = ref(false)

        const { maxChar } = useUtilities()
        const tempAgbText =
            "Lorem agb ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        const tempRevocationText =
            "Lorem revocation ipsum. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur."

        const router = useRouter()

        function acceptTerms() {
            allTermsModal.value = false
            router.push({ name: "order-3", params: { id: props.order.id } })
        }

        function setSignatureExists(val) {
            signatureExists.value = val
        }

        // btn controller
        const nextButtonEnabled = computed(() => {
            if (signatureRadio.value) {
                return agbRadio.value && revocationRadio.value
            } else {
                return (
                    agbRadio.value &&
                    revocationRadio.value &&
                    signatureExists.value
                )
            }
        })

        const { setStep2NextBtn } = useBtnController()

        watch(
            () => nextButtonEnabled.value,
            (val) => setStep2NextBtn(val),
            { immediate: true }
        )

        // first time selection of tab no2 initializes module, the next ones just change it's display (also take a look at template)
        const signatureModuleInitialized = ref()
        const tabsLocked = ref(false)
        watch(
            () => tab.value,
            (val) => {
                if (val == 2 && !signatureModuleInitialized.value)
                    signatureModuleInitialized.value = true
            }
        )

        // bus
        const emitter = useUtilities().getEmitter()

        function sendAgb() {
            sendAgbModal.value = false
            agbModal.value = false

            emitter.emit("setLoadingModal", true)
            setTimeout(() => {
                emitter.emit("setLoadingModal", false)
            }, 1000)
            setTimeout(() => {
                emitter.emit("showSuccessModal")
            }, 1000)
        }

        function sendRevocation() {
            sendRevocationModal.value = false
            revocationModal.value = false

            emitter.emit("setLoadingModal", true)
            setTimeout(() => {
                emitter.emit("setLoadingModal", false)
            }, 1000)
            setTimeout(() => {
                emitter.emit("showSuccessModal")
            }, 1000)
        }

        return {
            tab,
            agbRadio,
            revocationRadio,
            agbModal,
            revocationModal,
            nextButtonEnabled,
            signatureRadio,
            allTermsModal,
            acceptTerms,
            signatureExists,
            setSignatureExists,
            tempAgbText,
            tempRevocationText,
            maxChar,
            signatureModuleInitialized,
            sendAgbModal,
            sendAgb,
            sendRevocation,
            sendRevocationModal,
            tabsLocked
        }
    }
}
</script>