<template lang="pug">
BaseLayout
    Step2(
        :order="currentOrder"
    )
</template>

<script>
import Step2 from "@/components/sections/order-processing/Step2"

import { useUtilities } from "@/composables/utilities"

export default {
    components: {
        Step2
    },

    setup() {
        const { currentOrder } = useUtilities()
        return { currentOrder }
    }
}
</script>